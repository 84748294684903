<template>
  <div class="PerformStep">
    <div class="content">
      <div class="introduce">
        <img
          class="introduce-pic"
          src="../../assets/image/质量保函.png"
          alt=""
        >
        <div class="introduce-text">
          <div class="text-tit">
            质量保函
          </div>
          <div class="text-detail">
            质量保函是指：也称为“维修保函”
          </div>
          <a-button
            class="text-btn"
            type="primary"
            @click="$router.push({ path: '/performance', query: { type: '2' } })"
          >
            立即申请
          </a-button>
        </div>
      </div>
      <div class="describe">
        <div class="describe-list">
          <div class="list-tit">
            一、产品定义：
          </div>
          <div class="list-text">
            质量保函，也称为“维修保函”，是指应供货方或承建人申请，向买方或业主保证，如货物
            或工程的质量不符合合同约定而卖方或承建人又不能依约更换或修理时，按买方或业主的索
            赔予以赔偿的书面文件。
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            二、担保方式：
          </div>
          <div class="list-text">
            ① 专业担保公司的保函
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            三、担保额度:
          </div>
          <div class="list-text">
            根据工程价款比例缴存，一般为工程总价2%-5%。
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            四、担保期限:
          </div>
          <div class="list-text">
            质量担保的有效期应当在保证合同中约定，一般1-3年。
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            五、客户申请提交资料清单：
          </div>
          <div class="list-text">
            1.企业基本资料：营业执照、财报报告、法人代表身份证等；2.工程资料：招标文件，中标
            通知书保函格式施工合同、验收自理、项目要求的其他资料
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
};
</script>
<style scoped>
.content{
  width: 1500px;
  margin: 0 auto;
  padding: 30px 140px;
}
.introduce{
    display: flex;
    margin-bottom: 50px;
}
.introduce-text{
    margin-left: 30px;
}
.text-tit{
    font-size: 25px;
    font-weight: 800;
    margin-bottom: 5px;
}
.text-detail{
    line-height: 32px;
    margin-left: 5px;
}
.describe-list{
    margin-bottom: 50px;
}
.list-tit{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}
.list-text{
     font-size: 14px;
     line-height: 30px;
}
</style>
